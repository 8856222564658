import { useState  } from 'react'
import { useEffect } from 'react'
import { Subject   } from 'rxjs'
import { filter    } from 'rxjs/operators'

export * from './Topics'
export const publish = (topic, data) => { subject.next({ topic, data }) };

let subject = new Subject();

export function Subscriber(props) {
  const [state, setState] = useState({ topic: props.topic, data: null });
  const unsub = subject.pipe(filter(f => f.topic === state.topic))
                       .subscribe(s => setState({ data: s.data }));
  
  useEffect(() => { }, [state, unsub, props]);

  return props.children(state);
}
