import { useRef } from 'react';
import { GetMoviesByName } from '../../Common/Libraries/OMDB/OmdbAPI';
import Tile from './Tile';
import Pages from './Pages'

const getNewPage = async(name, page) => GetMoviesByName(name, page);

export default function Tiles({ data }) {
	
	let refTable   = useRef()
	let element	   = (<table ref={refTable} id="tblTiles" key="0"><tbody></tbody></table>);
	let rowIndex   = 0;
	let colIndex   = 0;
	let props	   = null; 
 
	if (data !== null) {
		const { response, name, choice, total, page, pages, tiles} = data || null;

		if (response && name) {
			props = { total,
                      name,
                      choice,
                      page, 
                      pages, 
                      previousClassName: "submitButtonPage", 
                      nextClassName: "submitButtonPage",
                      getNewPage
                    }
			if (props);
		}
			
		if (tiles) {
			element =
            (
                <>
                    <div>
                        <table id="tblTiles" className="tilesPane darkenedTableBackground" style={{borderSpacing: "2px", textAlign: 'centerAlignedText'}}>
                            <tbody>
                                {(tiles.map((parent) =>
                                (
                                    <tr key={(rowIndex++)} className="smallPosterHeight">
                                        {(Object.values(parent).map((child) => (<Tile key={(colIndex++)} {...child} />)))}
                                    </tr>
                                ))
                                )}
                            </tbody>
                        </table>
                        <div style={{padding: '5px'}}>
                            <Pages {...props}/>
                        </div>
                    </div>
                </>
            )
		}
	}

	return element;
}