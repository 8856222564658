export default function Rating({stars, source, visible = false}) {
    let rating  = Number(stars) ? parseFloat(stars / 2.0).toFixed(1)*16 : 0;
    let label   = source ? `${Number(stars).toFixed(1)} · ${source}` : Number(stars) && Number(stars).toFixed(1);
    let element = [];

    element.push(
    (
        <div key={100}>
            <div key={101} style={{width: "80px", position: "absolute", top: 'auto', left: "0", height: '20px', clip: "rect(0px, 80px, 16px, 0px)"}}>
                <img key={102} id="imgStarsB" src="https://cdn.alex-souza.com/media/images/popcorn/stars.png" alt="Star Rating" width="80px" height="32px" />
            </div>
        </div>
    ));
    
    visible && element.push(
    (
        <div key={2000}>
            <div key={2001} style={{position: "relative", top: "-17px"}}>
                <div key={2002} style={{width: "80px", position: "absolute", top: "-20", left: "0", height: '21px', clip: `rect(16px, ${rating}px, 32px, 0px)`}}>
                    <img key={2003} className="starsRating" id="imgStarsF" src="https://cdn.alex-souza.com/media/images/popcorn/stars.png" alt="Star Rating" width="80px" height="32px" />
                </div>
            </div>
            <span key={3000} id="score" className="movieDetailsText" style={{verticalAlign: 'top', width: '100%', paddingLeft: '90px'}}>{label}</span>
        </div>
    ));
    
    return <div style={{position: "relative", width: '100%', textAlign: 'left', height: '20px', zIndex: '100'}}>{element}</div>;
}