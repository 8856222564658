import { GetMoviesByName } from '../../Common/Libraries/OMDB/OmdbAPI'
import Pagination from 'react-bootstrap/Pagination';
import './Pages.css'

let currentPage = 0;
let totalPages	= 0;
let pageItems   = 0;
let movieName   = null;
let selected    = 0;

const handlePageClick = async(e) => { 
	const lastPage = currentPage;
	let item = e.target.innerHTML;
	if (item.indexOf('(current)') > 0) return;
	
	item = item.indexOf('>First<')		> -1 ? '«' : item;
	item = item.indexOf('>Previous<') > -1 ? '‹' : item;
	item = item.indexOf('>Next<')		 > -1 ? '›' : item;
	item = item.indexOf('>Last<')		 > -1 ? '»' : item;

	switch(item) {
		case '«':
			currentPage = 1;
			break;
		case '‹':
			currentPage -= 1;
			break;
		case '›':
			currentPage += 1;
			break;
		case '»':
			currentPage = totalPages;
			break;
		default	:
			currentPage = parseInt(item) || 1;
	}

	currentPage = Math.max(currentPage, 1);
	currentPage = Math.max(currentPage, 1);
	currentPage = Math.min(currentPage, totalPages);
	
	if (lastPage === currentPage) return;
	GetMoviesByName(movieName + "*", currentPage, selected);
}

export default function Pages({ page, pages, name, choice }) {
	
	const maxPages = 13;
	movieName      = name;
	selected       = choice;
	currentPage	   = page;
	totalPages     = pages;
	pageItems      = [];

	let startPage = page;
	let endPage	 = pages > maxPages ? maxPages - 1 + page : pages;

	if (startPage < maxPages) {
		startPage = 1;
		endPage	 = maxPages;
	}
	else if (startPage + parseInt(maxPages/2) < pages - parseInt(maxPages/2)) {
			startPage = page - Math.floor(maxPages/2);
			endPage	 = page + Math.floor(maxPages/2);
	}
	else
	{
		startPage = pages - maxPages;
		endPage	 = pages;
	}
		
	startPage = Math.max(startPage, 1);
	endPage	 = Math.min(endPage, pages);

	for(let index = startPage; index <= endPage; index++) pageItems.push(index);

	const element = (
		<div className="pagination pagination-font">
			<Pagination size='sm' onClick={handlePageClick}>
				<Pagination.First disabled={page === 1}/>
				<Pagination.Prev disabled={page === 1} />
				{ pageItems.map((item) => ( <Pagination.Item key={item} active={item === page}>{item}</Pagination.Item> ))}
				<Pagination.Next disabled={page === pages}/>
				<Pagination.Last disabled={page === pages}/>
			</Pagination>
		</div>
	);
	return element;
}