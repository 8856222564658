import { useEffect } from 'react';

export default function Detect({keyCode = 27, handleCallBack}) {
    let element = (<></>);

    useEffect(() => {
        const handleKeyCode = (event) => handleCallBack && event.keyCode === keyCode && handleCallBack(true);
        window.addEventListener('keyup', handleKeyCode);

    return () => {
        window.removeEventListener('keyup', handleKeyCode);
    };

  }, );

  return element;
}