import React, { useEffect, useRef, useMemo, useState } from "react";
import DetectKey from "../../Common/Components/DetectKey"
import Rating from "./Rating";

export default function Details({data}) {
    
    const [closeModal, setCloseModal] = useState(false);
    const [divModal  , setDivModal  ] = useState(useRef("divModal"));

    let movieInfo = null;
    let movieTile = null;

    if (data) {
        movieInfo = data.movieInfo;
        movieTile = data.tile;
    }
    
    let element   = useMemo(() => {(<div ref={divModal} className='modal'></div>)}, [divModal]);
    if (setDivModal);

    useEffect(() => {
        return () => {
            data && closeModal && setCloseModal(false);
        }
    }, [closeModal, data, element])

    if (data && closeModal) {
        if (divModal.current) {
            divModal.current.className  = "modal-overlay-hidden"
            movieTile.Image.current.src = movieInfo.Poster;
            movieTile.Image.current.style.opacity       = 1.00;
            movieTile.Frame.current.style.verticalAlign = "top";
        }
        return element;
    }
    
    if (movieInfo) {
        const {ratings, average} = GetRatings(movieInfo.Ratings);
        let scoreSpan     = 'N/A';
        let scoreStyle    = {padding: '5px'};
        let titleClass    = "";
        let divTitleStyle = {display: 'flex', flexDirection: 'column', height: '60px'};

        if (movieInfo.Title.length > 50) {
            titleClass    = "Long";
            divTitleStyle.height = "53px";
        }
        
        if (ratings.length) {
            scoreSpan  = ratings;
            scoreStyle = {padding: '10px'};
        }

        element =
        (
            <div ref={divModal} className="modal-overlay-visible" onClick={(e) => { if (e.target.className === "modal-overlay-visible") { setCloseModal(true); e.stopPropagation(); }}}>
                <DetectKey key={27} handleCallBack={setCloseModal} />
                <table className="fullDetailsPane centeredAlignedTable">
                    <tbody>
                        <tr>
                            <td colSpan="3">
                                <div>
                                    <svg onClick = {(e) => { setCloseModal(true); }}
                                        style={{position: 'absolute', top: '-240px', left: '530px', width:"512px", height:"512px",  transform: 'scale(0.025)'}}>
                                        <path d="M437.5,386.6L306.9,256l130.6-130.6c14.1-14.1,14.1-36.8,0-50.9c-14.1-14.1-36.8-14.1-50.9,0L256,205.1L125.4,74.5  c-14.1-14.1-36.8-14.1-50.9,0c-14.1,14.1-14.1,36.8,0,50.9L205.1,256L74.5,386.6c-14.1,14.1-14.1,36.8,0,50.9c14.1,14.1,36.8,14.1,50.9,0L256,306.9l130.6,130.6c14.1,14.1,36.8,14.1,50.9,0C451.5,423.4,451.5,400.6,437.5,386.6z" fill="#828282"></path>
                                    </svg>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell inputGradient centerAlignedTopText cellPadding1px" colSpan="3">
                                <div style={divTitleStyle}>
                                    <span className={`movieTitle${titleClass}Text`}>{movieInfo.Title}</span>
                                    <div style={{position: 'relative', left: '350px', width: '100px'}}>
                                        <Rating stars={average} visible={(scoreSpan !== 'N/A')} />
                                    </div>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell centerAlignedText cellPadding10px" rowSpan="10">
                                <img src={movieInfo.Poster} alt={movieInfo.Title} onError={(e) => { e.target.onerror = null; e.target.src = "https://cdn.alex-souza.com/media/images/popcorn/na.png" }} />
                            </td>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Year</span>
                            </td>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsText">{movieInfo.Year}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Runtime</span>
                            </td>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsText">{movieInfo.Runtime}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Director</span>
                            </td>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsText">{movieInfo.Director}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Country</span>
                            </td>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsText">{movieInfo.Country}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Rated</span>
                            </td>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsText">{movieInfo.Rated}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Awards</span>
                            </td>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsText">{movieInfo.Awards}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Genre</span>
                            </td>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsText">{movieInfo.Genre.replaceAll(", ", " ᛜ ")}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Scores</span>
                            </td>
                            <td className="whiteBorderCell" style={scoreStyle}>
                                <div>
                                    <span className="movieDetailsText">{scoreSpan}</span>
                                </div>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell movieDetailsHeight">
                                <span className="movieDetailsLabel">Actors</span>
                            </td>
                            <td className="whiteBorderCell">
                                <span className="movieDetailsText">{movieInfo.Actors.replaceAll(", ", " ᛜ ")}</span>
                            </td>
                        </tr>
                        <tr>
                            <td className="whiteBorderCell inputGradient centerAlignedText moviePlot" colSpan="2" rowSpan="1">
                                <span className="moviePlotText">{movieInfo.Plot}</span>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
        )
    }
    return element;
}

function GetRatings(movieRatings) {
	const regex = /((?<divisor>\d{1,3}\.*(\d+)*)\/(?<quotient>\d+))|((?<percent>\d{1,3}\.*(\d+)*)%)/g;
	let ratings = (<></>);
	let items	 = [];
	let average = 0;
	
	for(let item of movieRatings) {
			const match = item.Value.match(regex)[0];
			let rating	= -1;

			if (String(match).indexOf('/') > -1) {
					const division = String(match).split("/");
					rating = Number(parseFloat(division[0])/parseFloat(division[1])).toPrecision(5)*10;
			}
			else if (String(match).indexOf('%') > -1)
					rating = parseFloat(String(match).replace("%","")).toPrecision(5)/10.0;

			if (rating > -1)
					items.push({Source: item.Source, Rating: rating})
	}

	if (items.length > 0) {
			ratings = (
					items.map((item, index) => ( <Rating key={index} stars={item.Rating} source={item.Source} visible={true} /> )
			));
	}

	if (items.length > 0)
			average = parseFloat((items.reduceRight((sum, { Rating }) => sum + Rating, 0)/items.length)).toPrecision(5);

	return {ratings, average};
}