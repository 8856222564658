import { publish } from '../../Functions'
import { newMoviesList, newMovieDetail, omdbAPIMessage } from '../RXjs/Topics'
import { chunk } from 'lodash';

export const OMDB_API_INI   = `👽👾🦠 Please, type a 👆🏼 name 👻🎃🎞`
export const OMDB_API_GET   = `🛸🛰🎄 · Searching for $option · 🧭🚀🌋`
export const OMDB_API_NIL   = `👽👾🦠 Sorry, no $option with name 👆 were found... 👻🐱‍🏍🤬`;
export const OMDB_API_ERR   = `🌋⚡🌠 Ooops! something went wrong with your request... 👻💀🐱‍🏍`;
export const OMDB_API_FET   = `🌋⚡🌠 Ooops! something went wrong, check your connection... 👻💀🐱‍🏍`;
export const OMDB_API_RES   = `🎥🎬🍿 $total $option reference$s 🎦📽🎞`
export const OMDB_API_KEY   = process.env.REACT_APP_OMDB_API_KEY;
export const GetMovieByIMDB = async(imdbId) => await GetMovieByIMDBAsync(imdbId);

const choices   = {0: "movie", 1: "series", 2: "episodes"}
let omdbMsgData = null;

export async function GetMoviesByName(name, pageNumber, choice = 0) {
    let page        = (pageNumber === 0) ? 1 : pageNumber || 1;
    let response    = null;
    let movies      = null;
    let tilesData   = null;
    let hintMsg     = "";
    let total       = 0;
    let pages       = 0;
    
    try {
        const omdbURL  = `https://www.omdbapi.com/?s=${name}&apikey=${OMDB_API_KEY}&type=${choices[choice]}&page=${pageNumber}`;
        response = await fetch(omdbURL);
        movies   = await response.json();
    } catch
    {
        omdbMsgData = { hint: OMDB_API_FET, page: ' ' };
        publish(omdbAPIMessage, omdbMsgData);
        return;
    }

    if (response.ok) {
        if (movies["Response"] === "True") {
            
            let tiles = chunk(Object.values(movies["Search"].sort((a, b) => parseInt(b.Year) - parseInt(a.Year))), 5);

            if (tiles !== null) {
                total       = parseInt(movies['totalResults']);
                pages       = Math.ceil(total / 10);
                tilesData   = { 'response': true, 'name': name, 'choice': choice, 'total': total, 'page': page, 'pages': pages, 'tiles': tiles};
                hintMsg     = OMDB_API_RES.replace("$total", total).replace("$s", (total > 1 ? "s" : "")).replace("$option", choices[choice]);
                omdbMsgData = { hint: hintMsg, page: `Showing page ${page} of ${pages}`, pagination : {current: page, total: pages} };
            }
            else
                omdbMsgData = { hint: OMDB_API_ERR, page: `Showing page ${page} of ${pages}` };
        }
        else
            omdbMsgData = { hint: OMDB_API_NIL.replace("$option", choices[choice]), page: ' ' };
    }
    else {
        omdbMsgData = { hint: OMDB_API_ERR, page: ' ' };
    }
    
    publish(newMoviesList, tilesData);
    publish(omdbAPIMessage, omdbMsgData);
}

export async function GetMovieByIMDBAsync(imdbId, tile) {
    let movieInfo = null;
    let response  = null;
    try {
        const omdbURL = `https://www.omdbapi.com/?i=${imdbId}&apikey=${OMDB_API_KEY}`;
        response = await fetch(omdbURL);
    } catch  {
        omdbMsgData = { hint: OMDB_API_FET, page: ' ' };
        publish(omdbAPIMessage, omdbMsgData);
        return;
    }
    
    if (response.ok) {
        movieInfo = await response.json();
        publish(newMovieDetail, {movieInfo: movieInfo, tile: tile});
    }
    return movieInfo;
}