import { useRef, useState } from 'react';
import { GetMoviesByName } from '../../Common/Libraries/OMDB/OmdbAPI'
import { omdbAPIMessage, newMoviesList} from '../../Common/Libraries/RXjs/Topics'
import { publish } from '../../Common/Functions';

const handleOptionM = async (e) => { changeOptions(0); }
const handleOptionS = async (e) => { changeOptions(1); }
const handleOptionE = async (e) => { changeOptions(2); }
const handleNameOnPress = async (e) => { if (e.key === "Enter") { GetCurrentPage(0, selected); } }
const handleFindOnClick = async (e) => { GetCurrentPage(0, selected); }
let hint        = "👽👾🦠 Please, type 👆🏼 name 👻🎃🎞"
let page        = " ";
let options     = null;
let refInput    = null;
let refBtnFind  = null;
let setSelected = null;
let selected    = 0;
let pagination  = null;

export default function Search({ data }) {
    
    const [choice, setChoice] = useState(0)
    const refMovie   = useRef();
    const refSeries  = useRef();
    const refEpisode = useRef();
    refInput         = useRef('refInput')
	refBtnFind       = useRef('refBtnFind')
    setSelected      = setChoice;
    options          = { 0: {"ref": refMovie  , "placeHolder": "Movie"  },
                         1: {"ref": refSeries , "placeHolder": "Series" },
                         2: {"ref": refEpisode, "placeHolder": "Episode"}}
    
    if (choice);
    if (data !== null) {
        hint       = data.hint || hint;
        page       = data.page || page;
        pagination = data.pagination;
    }

    const element = (
        <table id='tblSearch' style={{width: "100%", height: "0px"}}>
            <tbody>
                <tr>
                    <td style={{height: '7px'}} />
                </tr>
                <tr className='searchGradient centerAlignedText'>
                    <td>
                        <div>
                            <button ref={refMovie}   id='btnMovies'   className='selectedChoice'  type='button' onClick={handleOptionM}>M</button>&nbsp;
                            <button ref={refSeries}  id='btnSeries'   className='selectChoices'   type='button' onClick={handleOptionS}>S</button>&nbsp;
                            <button ref={refEpisode} id='btnEpisodes' className='selectChoices'   type='button' onClick={handleOptionE}>E</button>&nbsp;&nbsp;
                            <input  ref={refInput}   id='txtName'     className='inputChoiceText' type='text'   size='40' maxLength='100' placeholder='Movie' pattern='([A-za-z]|[0-9])+' onKeyPress={handleNameOnPress}/>&nbsp;&nbsp;
                            <button ref={refBtnFind} id='btnFind'     className='submitButton'    type='button' onClick={handleFindOnClick}>SEARCH</button>
                        </div>
                    </td>
                </tr>
                <tr className='searchGradient'>
                    <td>
                        <span id="lblHint" className='labelText centerAlignedTopText'>{hint}</span><br></br>
                        <span id="lblPage" className='subLabelText centerAlignedTopText'>{page}</span>
                    </td>
                </tr>
                <tr>
                    <td style={{height: '7px'}} />
                </tr>
            </tbody>
        </table>
    );

    return element;
}

function GetCurrentPage(increment, choice)
{
    let page = 1;
    if (increment > 0)
        page = Math.min(Math.max(0, parseInt(pagination.current) + increment), parseInt(pagination.total));

    ValidateInputField({refInput, refBtnFind}, page, choice);
}

function changeOptions(choice = 0) {
    refInput.current.placeholder = options[choice].placeHolder;
    setSelected(choice);
    selected = choice;

    for(let option in options)
        options[option].ref.current.className = "selectChoices";
    options[choice].ref.current.className = "selectedChoice";

    if (refInput.current.value !== '')
        GetCurrentPage(0, selected);
}

async function ValidateInputField(refs, page = 1, choice = 0) {
	const choices = {0: "movie", 1: "series", 2: "episode"}
	let omdbMsg	  = {hint: `🛸🛰🎄 · Searching for ${choices[choice]} · 🧭🚀🌋`, page: " "};

	const {refInput, refBtnFind} = refs;

	if (refInput.current && refInput.current.value.length >= 2) {
		refInput.current.value = String(refInput.current.value).replace(/\s{2,}/gm, ' ');
		GetMoviesByName(refInput.current.value + "*", page, choice);
		refBtnFind.current.focus();
	}
	else
		omdbMsg = {hint: "🛸🛰🦟 Please, enter a valid 👆🏼 name 🧭🎃🐱‍👤", page: " "};

	publish(omdbAPIMessage, omdbMsg)
	publish(newMoviesList , null);
}