import { Subscriber } from '../../Common/Libraries/RXjs/PubSub'
import { omdbAPIMessage, newMoviesList, newMovieDetail } from '../../Common/Libraries/RXjs/Topics'
import PageHeader from './Header';
import Search from './Search';
import Tiles from './Tiles';
import Details from './Details';

let element    = null;

function App() {
 
  element = (
    <>
      <PageHeader />
      <>
        <Subscriber topic={omdbAPIMessage}>
          {msg => (<Search {...msg} />) }
        </Subscriber>
        <Subscriber topic={newMoviesList}>
          {msg => (<Tiles data={msg.data} />)}
        </Subscriber>
        <Subscriber topic={newMovieDetail}>
          {msg => (<Details  data={msg.data} />)}
        </Subscriber>
      </>
    </>
  );
  return element;
}

export default App;
