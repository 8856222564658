import { useRef, useState    } from 'react';
import { randomString        } from '../../Common/Functions'
import { GetMovieByIMDBAsync } from '../../Common/Libraries/OMDB/OmdbAPI'

export default function MovieTile(movie) {
    
    const year      = movie.Year   === null      ? "N/A" : movie["Year"];
    const poster    = movie.Poster === undefined ? 'https://cdn.alex-souza.com/media/images/popcorn/na.png' : movie["Poster"];
    const title     = movie.Title  === null      ? "N/A" : movie["Title"];
    const imdbID    = movie.imdbID;
    const random    = randomString(8);
    const imgID     = `imgID${random}`;
    const frameID   = `tblDivID${random}`;
    const [refImage, setRefImage] = useState(useRef(imgID));
    const [refFrame, setRefFrame] = useState(useRef(frameID));
    if (setRefImage & setRefFrame);

    const element = (
        <td className="grayBorderCell centerAlignedTopText tileDimensions">
            <table className="movieTileWidth" onClick={async(e) =>
            { 
                    refImage.current.src = "https://cdn.alex-souza.com/media/images/popcorn/loading.gif";
                    refImage.current.style.opacity = 0.50;
                    GetMovieByIMDBAsync(imdbID, {Frame: refFrame, Image: refImage});
            }}>
                <tbody>
                    <tr>
                        <td>
                            <p className="subLabelText" style={{ height: '0px' }}>Year {year}</p>
                        </td>
                    </tr>
                    <tr>
                        <td ref={refFrame} className=" centerAlignedTopText" style={{ height: "245px", zIndex: '2' }}>
                            <img ref={refImage} src={poster} alt={imdbID.toUpperCase()} style={{maxWidth: '100%', borderSpacing: '0px', opacity: 1.00, maxHeight: '245px'}}
                                onError={(e) => { e.target.onerror = null; e.target.src = 'https://cdn.alex-souza.com/media/images/popcorn/na.png' }} />
                        </td>
                    </tr>
                    <tr>
                        <td>
                            <p className="tileContentText" style={{height: '55px'}}>{String(title).substring(0,60).trimEnd()}{String(title).length > 60 && "..."}</p>
                        </td>
                    </tr>
                </tbody>
            </table>
        </td>
    );

    return element;
}